.view-modal {
  width: 800px;
  height: 740px;
}

.object-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 93%;
}

.object-container textarea {
  resize: none;
}

.type-tag {
  width: 190px;
  height: 28px;
  color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 4px;
  padding: 6px 0 0 16px;
}

.msg {
  padding: 16px;
  position: absolute;
  top: 20%;
  transform: translate(0%, -50%);
}

.view-textarea {
  top: 51%;
}

.limit-text-height {
  top: 53%;
  height: 65% !important;
}

.view-button-container {
  top: 91%;
}

.keyname-input {
  position: absolute;
  top: 9%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 620px;
  padding-left: 16px;
  font-size: 26px;
  height: 40px;
  outline: none;
  transition: 300ms;
  border-radius: 4px;
  margin-top: 3px;
  margin-bottom: 8px;
  border: 1px solid var(--rowFocus);
}

.obj-image {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.clipboard-icon {
  position: absolute;
  right: 5%;
  z-index: 3;
  background: white;
  padding: 6px;
  border-radius: 8px;
  transition: 300ms;
  border: none;
}

.keyname-input:focus {
  border: 1px solid var(--primaryHover);
}

.read-only {
  color: var(--grayedText);
  cursor: default;
  border: 1px solid var(--rowFocus) !important;
}

.page-btn {
  position: absolute;
  top: 87%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.img-tab-container {
  width: 300px;
  display: flex;
  border-radius: 4px;
  cursor: pointer;
  color: var(--primary);
  font-size: 14px;
  position: absolute;
  top: 16%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.img-tab {
  width: 100px;
  text-align: center;
  padding: 0.4em 0.8em;
  border: 2px solid var(--primary);
  transition: 200ms;
}

.img-tab.left {
  border-radius: 4px 0 0 4px;
}

.img-tab.right {
  border-radius: 0 4px 4px 0;
  margin-left: -2px;
}

.img-tab:hover {
  background: var(--whiteBtnHover);
}

.img-tab.focus {
  background: var(--primary);
  color: white;
}

.img-tab.focus:hover {
  background: var(--primaryHover);
}

@media only screen and (max-width: 800px) {
  .view-modal {
    width: 100%;
    height: 80%;
    border-radius: 0;
  }
  .object-container {
    height: 90%;
  }

  .view-button-container {
    top: 90%;
  }

  .keyname-input {
    width: 95%;
  }
}
