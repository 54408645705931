.pdf-obj-list {
  display: table;
  text-align: left;
  width: 50%;
  border: 1px solid var(--grayDark);
  position: relative;
  border-bottom: none;
}

.pdf-obj-list > * {
  display: table-row;
}

.pdf-obj-list > *:nth-child(even) {
  background-color: var(--tableEven);
}

.pdf-obj-list > header .col {
  padding: 8px 4px;
}

.pdf-obj-list .col {
  display: table-cell;
  min-width: 260px;
  max-width: 700px;
}

.pdf-obj-list .clickable {
  text-decoration: underline;
}

header {
  background-color: var(--primary);
  color: white;
  line-height: 24px;
  top: 0;
  position: sticky !important;
}

.pdf-obj-list .key-col {
  width: 30%;
}

.pdf-obj-list .type-col {
  width: 20%;
}

.pdf-obj-list .col.value-col {
  min-width: 400px;
}

.pdf-obj-list .col.icon-col {
  min-width: 100px;
  position: relative;
}

.row > .col {
  line-height: 40px;
  border-bottom: 1px solid var(--grayDark);
}

.row {
  background: white;
}

.radio-select {
  width: 100%;
  height: 20px;
}

.icon-button {
  width: 30px;
  height: 22px;
  margin: auto;
  cursor: pointer;
  position: relative;
}

#cancel-button > img {
  height: 22px;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 800px) {
  .row:hover {
    background: var(--rowHover);
  }
  .row.selected {
    background: var(--rowFocus);
  }
}

@media only screen and (max-width: 800px) {
  .pdf-obj-list {
    max-width: 100%;
    min-width: 99%;
  }

  .pdf-obj-list .col.icon-col {
    min-width: 50px;
  }

  .pdf-obj-list .key-col {
    min-width: 80px;
  }

  .pdf-obj-list .type-col {
    min-width: 100px;
  }

  .pdf-obj-list .col.value-col {
    min-width: 100px;
  }

  .radio-select {
    margin-left: -4px;
  }
}

@media only screen and (max-width: 480px) {
  .pdf-obj-list .col.icon-col {
    width: 10%;
  }
  .pdf-obj-list .key-col {
    width: 20%;
  }
  .pdf-obj-list .col.value-col {
    display: none;
  }
}
