.delete-modal {
  width: 500px;
  height: 300px;
}

.message {
  height: 100px;
}

#show-modal-checkbox {
  margin-top: 50px;
}

.delete-modal-button-container {
  top: 78%;
}
