.tab-container {
  width: 500px;
  display: flex;
  border-radius: 4px;
  cursor: pointer;
  color: var(--primary);
  font-size: 16px;
  margin-bottom: 14px;
}

.tab {
  width: 100px;
  text-align: center;
  padding: 0.5em 1em;
  border: 2px solid var(--primary);
  transition: 200ms;
}

.tab.left {
  border-radius: 4px 0 0 4px;
}

.tab.right {
  border-radius: 0 4px 4px 0;
}

.tab.center,
.tab.right {
  margin-left: -2px;
}

.tab:hover {
  background: var(--whiteBtnHover);
}

.tab.focus {
  background: var(--primary);
  color: white;
}

.tab.focus:hover {
  background: var(--primaryHover);
}

.tab.disabled {
  color: white;
  border: 2px solid white;
}

@media only screen and (max-width: 800px) {
  .tab-container {
    width: 400px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 390px) {
  .tab-container {
    width: 272px;
  }
  .tab.right {
    display: none;
  }
}
