.mobile-add-btn {
  display: none;
}

@media only screen and (max-width: 800px) {
  .mobile-add-btn {
    display: flex;
    position: fixed;
    bottom: 12%;
    right: 30px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: var(--primary);
    box-shadow: var(--modalBoxShadow);
  }
}
