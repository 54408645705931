.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.App:focus {
  outline: none;
}

.vr {
  margin: 0 0.5em;
  border-left: 3px solid var(--primary);
  height: 2em;
}

.btn {
  padding: 0.5em 1em;
  margin: 3px;
  background-color: var(--primary);
  color: #ffffff;
  border-radius: 4px;
}

.btn.contents {
  width: 90px;
  text-align: center;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: var(--componentBoxShadow);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown-content.add-menu {
  width: 520px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.clickable {
  cursor: pointer;
  transition: 300ms;
}

.disabled,
.disabled:hover {
  cursor: default !important;
  background: grey !important;
}

.btn.clickable.disabled,
.btn.clickable.disabled:hover {
  cursor: default;
  background: grey;
  border: 1px solid grey;
}
.btn.clickable:hover {
  background: var(--primaryHover);
}

.col.clickable:hover {
  color: blue;
}

.hide {
  display: none !important;
}

.footer {
  height: 100px;
  bottom: 0;
  width: 100%;
  margin: 0;
}

.tooltip {
  text-align: center;
  border: 1px solid white;
}

@media only screen and (max-width: 800px) {
  .App {
    overflow: hidden;
  }

  .footer {
    height: 0;
  }
}
