.nav-container {
  width: 100%;
  margin: 0.5em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

@media only screen and (max-width: 800px) {
  .nav-container {
    display: none;
  }
}
