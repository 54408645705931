@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

:root {
  --primary: #14213d;
  --primaryHover: #404c76;
  --rowHover: #e1dee6;
  --rowFocus: #dbd7e1;
  --red: #f75e5e;
  --tableEven: rgb(241, 233, 236);
  --grayDark: #bbb;
  --grayedText: #616161;
  --modalBoxShadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
  --componentBoxShadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
  --whiteBtnHover: rgba(0, 0, 0, 0.05);
  --modalWrapper: rgba(0, 0, 0, 0.3);
  --modalBorder: #cfd4da;
  --inputDisable: #e6e6e6;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
