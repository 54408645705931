.modal-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  background: var(--modalWrapper);
}

.modal {
  background: white;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: var(--modalBoxShadow);
}

.title {
  padding: 20px;
  font-weight: 700;
  font-size: 20px;
  border-bottom: 1px solid var(--modalBorder);
}

.paddingContainer {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-bottom: 1px solid var(--modalBorder);
}

.modal-textarea {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 70%;
  font-size: 15px;
  transition: 300ms;
  outline: none;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 16px;
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid var(--rowFocus);
}

.modal-textarea:focus {
  border: 1px solid var(--primaryHover);
}

.button-container {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  justify-content: space-around;
}

.standard-button-container {
  top: 90%;
}

button {
  padding: 0.5em 1.3em;
  border-radius: 4px;
  font-size: 1.12em;
  cursor: pointer;
  transition: 400ms;
  margin: auto 4px;
}

.btn-primary {
  background: var(--primary);
  border: 1px solid var(--primary);
  color: white;
}

.btn-primary:hover {
  background: var(--primaryHover);
}

.btn-secondary {
  color: var(--primary);
  border: 1px solid var(--primary);
  background: white;
}

.btn-secondary:hover {
  background-color: var(--whiteBtnHover);
}

.disabled {
  border: 1px solid var(--grayedText);
}

@media only screen and (max-width: 800px) {
  .button-container {
    width: 200px;
  }
}
