.security-modal {
  width: 600px;
  height: 500px;
}

.settings {
  height: 400px;
}

.choice-container {
  padding: 4px;
}

.input-container {
  margin-left: 4px;
  line-height: 28px;
}

.password-input {
  left: 160px;
  width: 300px;
  position: absolute;
  margin-left: 8px;
  font-size: 16px;
  transition: 300ms;
  border-radius: 2px;
  border: 1px solid var(--modalBorder);
  height: 20px;
  padding: 4px 8px;
  outline: none;
}
.password-input:disabled {
  background: var(--inputDisable);
}

.security-button-container {
  top: 85%;
}

@media only screen and (max-width: 800px) {
  .security-modal {
    width: 100%;
    height: 80%;
    border-radius: 0;
  }
  .settings {
    height: 70%;
  }

  .security-button-container {
    top: 90%;
  }

  .password-input {
    max-width: 40%;
  }
}
