.pdf-obj-list > header .col.obj-col,
.row.xref > .col.obj-col {
  padding-left: 30px;
}

.pdf-obj-list.xref .col {
  min-width: 145px;
  cursor: pointer;
}

.pdf-obj-list.xref .row.xref.clickableXref:hover {
  text-decoration: underline;
  color: blue;
}

.pagination-btn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 30px;
}

@media only screen and (max-width: 800px) {
  .pdf-obj-list.xref .col {
    max-width: fit-content;
    min-width: 50px;
  }

  .pdf-obj-list > header .col.obj-col,
  .row.xref > .col.obj-col {
    padding-left: 10px;
  }
}
