.no-doc-msg {
  text-align: center;
  padding-top: 20px;
  background: var(--tableEven);
  width: 1050px;
  height: 25vh;
  border-radius: 0 0 4px 4px;
  border: 1px solid var(--grayDark);
  border-top: none;
}

.no-doc-msg > h4,
.no-doc-msg > h2 {
  font-weight: 400;
}

.no-doc-msg > h4 {
  color: var(--grayedText);
}

@media only screen and (max-width: 800px) {
  .no-doc-msg {
    max-width: 99%;
  }
}
