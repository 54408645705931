.mobile-nav-container {
  display: none;
}

@media only screen and (max-width: 800px) {
  .mobile-nav-container {
    display: flex;
    background: var(--primary);
    color: white;
    position: fixed;
    bottom: 0;
    height: 9%;
    width: 100%;
    text-align: center;
    align-items: center;
  }
  .nav-btn {
    margin: auto;
  }

  .svg_icons {
    transform: scale(1.8);
  }
}
