.breadcrumbs {
  margin: 0.5em;
  display: flex;
  align-items: center;
}

.caption {
  font-weight: bold;
}

.type-container {
  display: flex;
}

.new-object-input {
  margin: auto;
  margin-left: 4px;
  height: 28px;
  width: 180px;
  font-size: 16px;
  padding-left: 6px;
  transition: 300ms;
  border-radius: 4px;
  outline: none;
  border: 2px solid var(--grayDark);
}

.new-object-input.error {
  border: 2px solid var(--red);
}

#add-stream {
  width: 158px;
  margin: auto;
  text-align: center;
}

.key-name-input {
  margin: auto;
  height: 28px;
  width: 180px;
  font-size: 16px;
  padding-left: 6px;
  transition: 300ms;
  border-radius: 4px;
}

.key-name-input.error {
  border: 2px solid var(--red);
}

@media only screen and (max-width: 800px) {
  .breadcrumbs {
    display: none;
  }
}
