.toolbar {
  width: 500px;
  height: 50px;
  background: var(--tableEven);
  position: fixed;
  border-radius: 4px;
  bottom: 10%;
  box-shadow: var(--componentBoxShadow);
  border: 1px solid var(--primary);
}

.toolbar-btn-container {
  display: flex;
  width: 223px;
  margin: auto;
  margin-top: 6px;
}

#edit-btn {
  margin-right: 20px;
}

@media only screen and (max-width: 800px) {
  .toolbar {
    width: 80%;
    transform: scale(1.1);
    bottom: 12%;
  }
}
