.create-modal {
  width: 800px;
  height: 700px;
}

#create-key-input {
  margin-top: 8px;
}

.value-wrapper {
  position: absolute;
  top: 22%;
  transform: translate(0%, -50%);
}

.index-container {
  position: absolute;
  top: 9%;
  padding-left: 4px;
}

.create-textarea {
  top: 53%;
}

@media only screen and (max-width: 800px) {
  .create-modal {
    width: 100%;
    height: 80%;
    border-radius: 0;
  }

  #create-key-input {
    width: 95%;
  }
}
